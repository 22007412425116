h1 {
    margin-top: 5px;
}

img {
    margin-top: 30px;
}

.initial, .declined, .result, .quiz {
    margin: auto;
    width: 600px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flexContainer {
    margin: 30px 0;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

.result .flexContainer {
    justify-content: center;
}

.modeSelectCopy {
    text-align: left;
    margin:0 40px 0 0;
    flex-grow: 1;
}

.checkContainer {
    margin-right: 25px;
}

.checkContainer > div {
    margin: 10px 0;
    font-size: 12px;
}

.check, .thankYouNext {
    border-radius: 4px;
    border-width: 0;
    cursor: pointer;
    user-select: none;
    height: 38px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.37);
    font-size: 14px;
    flex-shrink: 0;
    padding: 0 20px;
}

.check {
    background-color: #43A3DA;
    color: white;
}

.result .check {
    margin-right: 30px;
}

.thankYouNext {
    background-color: white;
    color: #656565;
}

.initial .check {
    width: 170px
}

.initial .thankYouNext {
    width: 125px;
}

.declined .check, .declined .thankYouNext {
    width:48%
}

.declined .check {
    margin-right: 4%;
}

.advantages {
    width: 28%;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.advantages:nth-of-type(2) {
    margin: 0 14%;
}

.advantages > p {
    flex-flow: 1;
    margin:0;
    font-size: 14px;
}

.bullet {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #29B573;
    margin-right: 10px;
    margin-top: 4px;
    flex: 0 0 11px;
}

.reasons {
    display: flex;
    align-items: center;
    width: 30%;
    background-color: #E7F2F9;
    padding: 10px;
    align-self: stretch;
}

.reasons:nth-of-type(2) {
    margin: 0 5%;
}

.result > h2 {
    margin-top: 20px;
}

.result > p {
    margin-bottom: 40px;
}

.prosperBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin: 0 10px 10px 0px;
  color: #29B573
}

.prosperBox:last-of-type {
  padding-left: 25px;
}

.prosperLargeFigure {
  font-size: 40px;
  font-weight: 600;
  margin: 4px 0;
}

.prosperMo {
  font-size: 20px;
}

.prosperLabel {
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  margin: 0;
}

.prosperSavingsLabel {
  font-size: 16px;
  font-weight: 300;
  margin: 20px 0 0 0;
}

.prosperSavingsLabel > span {
  font-weight: 400;
}

.benefit {
  text-align: left;
  color: #656565;
  margin: 7px 0;
  padding-left: 1em;
}

.benefit > span {
  font-weight: 600
}

.benefit:before {
  content: "▶";
  font-size: 10px;
  margin-right: 1em;
  margin-left: -2em;
}

.prosperEmployerPaysLabel {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
@import 'Components/global.scss';

$h1-font-weight: 600;
$h1-font-size: 1.75rem;
$h1-line-height: unset;

$another-light-gray: #FAFAFA;

.header {
  margin: 0 20px;

  h1 {
    font-weight: $h1-font-weight;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    color: $secondary-color;
    margin-top: 0;
  }

  p {
    margin-top: 10px;
    font-size: 1.20rem;
    font-family: $zilla;
  }

}

.calculatorContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: flex-end;
  align-items: flex-start;
  margin-top: 20px;

  h1 {
    font-weight: $h1-font-weight;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    color: $secondary-color;
    margin-left: 15px;
  }

  h2 {
    font-weight: 600;
    font-size: .75rem;
    letter-spacing: 0.08rem;
    text-align: left;
    color: $blue;
    text-transform: uppercase;
    margin: 0 0 20px;

    &:not(:first-of-type) {
      margin: 20px 0 10px 0;
    }

  }

  h3 {
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: 0.02em;
    line-height: 24px;
    color: $secondary-color;
    margin: 0;
  }

  /*
    Doing weird margin and padding offsets to avoid
    column calculations while adhering to the designs
  */
  > div {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 20px;

    &:first-of-type {
      padding: 20px 0;
    }

    &:nth-child(2) {
      background-color: $mint;
      padding: 20px;
      margin: 0;
    }

    &:last-of-type {
      padding-right: 0;
      margin-left: 20px;
      padding: 20px 0 0;

      div {
        height: 120px;
        background-color: $another-light-gray;
        padding: 25px;
        border-radius: 8px;
        flex: 1;

        &:nth-of-type(2) {
          background-color: $mint;
          margin-top: 10px;

          h1 {
            color: $green
          }

        }

        p {
          font-size: 1rem;
          font-weight: 400;
          margin: 0;

          &:nth-of-type(2) {
            color: $green;
            font-weight: $h1-font-weight;
            text-align: right;
          }

        }

      }

    }

    input {
      background-color: $another-light-gray
    }

    div:first-of-type > input {
      margin-bottom: 10px;
    }

  }

}
@import 'Components/global.scss';

.plansContainer {
  width: 75%;
  max-width: 1400px;
  margin: 0 auto 70px;
}

.contributionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 25px;
}

.contributionContainer > h2 {
  font-family: $proxima;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  margin: .25rem 0 0;
}

.copy {
  color: #707070;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 16px;
}

@import 'Components/global.scss';
@import 'Components/Stargate/ShowPlans/Components/ShowPlansHeader.module.scss';

.mainContainer {
  max-width: $container-max-width;
  min-width: $container-min-width;
  width: 100%;
  margin: 0 auto;
  padding: 3rem $container-padding-horizontal 0;

  .plansAndFilter {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;
  }

  .plansListContainer {

    overflow: visible;

    h2, h3 {
      position: relative;
      font-family: $zilla;
      color: $navy-blue;
      margin: 0;
      overflow: visible;
      padding-left: 30px;
    }

    h2 {
      font-size: 1.25rem;
      text-align: left;
      &:before {
        content: '';
        height: 90px;
        background-color: $mint;
        width: 5px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    h3 { font-size: 1rem; }

    .erSection {
      display: grid;
      grid-template-columns: 1fr 1.25fr;
      gap: 75px;
      margin-bottom: $shop-top-spacing;

      .contributionContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
          width: $buttonWidth;
          height: $buttonHeight;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
          font-size: .875rem;
          border-radius: 4px;
          background-color: $gray;
          color: $navy-blue;
          margin-right: 4px;
        }

        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $navy-blue;

          .bigPerc {
            font-size: 2.25rem;
            display: inline-block;
            margin-right: 5px;
          }

          .contType {
            display: inline-block;;
            font-size: 1rem;
            text-transform: uppercase;
          }

        }

      }

    }

    .eeSection {

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: $shop-top-spacing;
      background-color: rgba( $sky-blue, .3 );
      padding: 20px 20px 20px 0 ;

      a {
        display: block;
        height: 35px;
        width: 135px;
        text-align: center;
        line-height: 35px;
        border-radius: 4px;
        font-size: .75rem;
        background-color: $sky-blue;
        color: $navy-blue;
        cursor: pointer;
        text-decoration: none;

        &:link, &:visited, &:hover, &:active {
          color: $navy-blue;
        }

      }

    }

  }

}
@import 'Components/global.scss';

.container {
  height: calc(80vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.compareTable {
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;

  td, th {
    border-right: 1px solid $disabled-gray;

    &:first-child {
      background-color: white;
      width: 12.5rem;
    }

    &:not(:first-child) {
      width: 12rem;
      text-align: center;
    }

  }

  th { padding: 0 .5rem }
  td {
    padding: 1rem;
    &:first-child { padding: 1rem .75rem 1rem 0 }
  }

  // Modal title
  th:first-child {
    font-size: 2rem;
    color: $periwinkle;
    font-weight: 700;
    text-align: left;
    padding: 0;
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: rgba($mint, .4);
    }

    // Last row: "Savings with us"
    &:last-child td {
      color: $green !important;

      &:not(:first-child) {
        background-color: $mint;
      }
    }

    td {
      color: $navy-blue;
      font-weight: 500;
    }

    // Row header
    td:first-child {
      color: $navy-blue;
      font-size: .875rem;
      font-weight: 600;

      span {
        display: block;
        font-size: .75rem;
        font-weight: 300;
      }
    }

    td:nth-child(2) {
      background-color: rgba($mint, .3);
    }
  }

  // User current plan inputs
  input, .prosperBenefitToggle {
    color: inherit;
    font-size: inherit;
    text-align: inherit;
    font-weight: inherit;
    text-transform: inherit;
    background-color: transparent;
    border: none;
    width: 100%;
    border-bottom: 1px solid darken($color: $disabled-gray, $amount: 10);

    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px #fff inset;
    }

    &:not(output):-moz-ui-invalid {
      box-shadow: none
    }
  }

}

// 2nd column header
.currentPlan {
  font-size: .825rem;
  background-color: rgba($mint, .5);

  span {
    display: block;
    font-family: $zilla;
    font-weight: 400;
    font-size: .75rem;
  }
}

// User selected plans
.selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .625rem;
  position: relative;

  img {
    margin: 0 0 .5rem;
    max-width: 120px;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    padding: .125rem;
    background-color: $gray;
    color: $primary-color;
    border-radius: 50%;
    font-size: 1rem;
  }
}

// Cell in rows with the best value
.best { color: $green !important }

// For prosper buttons and
.notIncluded {
  color: $orange !important;
  text-transform: capitalize;
}

.small {
  font-size: .75rem;
  line-height: .75rem;
}

// True cost of care
.tcoc div {
  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    color: $green;
    margin-top: .75rem;
  }

  span {
    text-transform: uppercase;
    color: $primary-color;
    font-size: .625rem;
    letter-spacing: .09rem;
  }
}
@import 'Components/global.scss';

.planPremiumBox {
  padding: 0 !important;
  margin-bottom: 10px;
  height: 100%;
  overflow: hidden;
  color: $green;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
}

.header {
  height: 3.125rem;
  background: $green;
  color: white;
  font-size: .75rem;
  text-transform: uppercase;
  line-height: 3.125rem;
  font-weight: 700;
  letter-spacing: .07rem;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .youPay {
    font-size: 1.75rem;
  }

  .largeFigure {
    font-size: 3rem;
    margin: .5rem;
    span { font-weight: 600; }
  }

  .duration {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .07rem;
  }

  .copy {
    font-size: 1rem;
    margin: 1.5rem 1.5rem 0;
  }

  .disclaimer {
    font-size: .875rem;
    font-weight: 600;
    color: $secondary-color;
    margin-top: 2rem;
  }

}


.half {
  display: inline-block;
  height: 100%;
  width: 50%;
  vertical-align: top;
}
.year {
  width: 100px;
  text-align: center;
  display: inline-block;
  vertical-align: top;

}

.dateInput {
  width: 100%;
  font-family: proxima-nova, sans-serif;
  font-size: 40px;
  outline: none;
  border: none;
  text-align: center;
  color: #D9D9D9;
  padding-top: 0;
}

.yearPre {
  font-size: 40px;
}

.baseline {
  border-bottom: 1px solid #959595;
  transform: scaleY(1.8);
}

.slash {
  font-size: 40px;
  margin: 0 6px 0 10px;
  height: 100%;
  vertical-align: top;

}

.month, .day {
  width: 50px;
  text-align: center;
  height: 100%;
  display: inline-block;
}

.date {
  height: 100px;
}

.unitLabel {
  font-size: 12px;
}

.calendar {
  vertical-align: top;
  display: inline-block;
  height: 100%;
}

.calendarIcon {
  width: 38px;
  filter: invert(.5);
  cursor: pointer;
  margin-left: 15px;
  margin-top: 5px;
}

.pickerHolder input {
  visibility: hidden;
  width: 0;
  height: 0;
  position: relative;
}

.pickerHolder {
  position: relative;
  top: -17px;
  left: -17px;
  height: 0;
  width: 0;
}

.validDate {
  color: #48A3DB;
}
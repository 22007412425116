@import 'Components/global.scss';

.header {
  margin-top: 0;
}

.addEmpForm {
  width: 100%;
  display: grid;
  gap: 20px;
  row-gap: 10px;
  grid-template-columns: 1fr 1fr;

  fieldset > * {
    margin-bottom: 10px;
  }

  input {
    background-color: $white;
  }

  /*
      Separate class because adding !important to
      input's backgroundColor introduces an artifact
      in the county picker
  */
  .whiteBackground {
    background-color: $white !important;
  }

  .subgrid {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }

  .submit {

    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-top: 20px;
      text-align: right;
      margin-right: .75rem;
    }
  }

}
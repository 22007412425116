@import 'Components/global.scss';

.welcome {
  font-size: $shop-h1-font-size !important;
  font-weight: $shop-h1-font-weight !important;
  text-align: center;
  color: $periwinkle !important;
}

.desc {
  color: $primary-color;
  font-size: 24px;
  font-family: $zilla;
  text-align: center;
  margin-top: 0;
  text-align: center;
}

.modalContainer {

  display: flex;
  flex-direction: column;
  align-items: center;

  .employer, .employee {
    color: white;
    width: 265px;
    height: 62px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 7px;
    font-size: .875rem;
  }

  .employer {
    background-color: #406fcc;
    margin-bottom: 15px;
  }

  .employee {
    background-color: #48a3db;
  }

  .logout {
    color: grey;
    background: none;
    text-decoration: underline;
    outline: none;
    border: none;
    margin-top: 15px;
    font-size: 18px;
  }
}
@import 'Components/global.scss';

.prosperContainer {
  position: relative;
  height: 390px;
  width: 100%;
  background: linear-gradient($blue, $periwinkle);
  position: relative;
  padding-top: $shop-top-spacing;
  overflow: visible;
  z-index: 3;

  .oval {
    position: absolute;
    content: '';
    width: 100%;
    height: 90px;
    background-color: $periwinkle;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    bottom: -90px;
    z-index: -1;
  }

  h1 {
    font-size: $shop-h1-font-size;
    font-weight: $shop-h1-font-weight;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-bottom: $shop-top-spacing;
  }

}

.prosperContent {
  width: 840px;
  display: grid;
  grid-template-columns: 1.15fr 1fr 80px;
  gap: 30px;
  margin: 0 auto;

  .prosperLogo{
    height: 70px;
  }

  .prosperList {
    // Aligning the list to the Prosper logotype
    margin: 30px 0 0 75px;

    li {
      list-style: none;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.07em;

      &:not(:first-of-type) {
        margin-top: 5px;
      }
    }
  }

  .prosperSavings {
    font-family: $zilla;
    font-weight: 300;
    font-size: 1.25rem;
    color: #ffffff;
    line-height: 1.3em;
    margin-top: 8px;

    span {
      font-weight: 600;
    }
  }

  .floatingProsperHeart {
    background: $prosper-icon;
    background-size: 80px;
    background-position: center bottom;
    margin-bottom: 20px;
  }

}

.prosperDetailsButton {
  display: block;
  width: 320px;
  height: 75px;
  background-color: #4A7AD9;
  border-radius: 8px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  transition: background-color 200ms ease-in;
  margin-top: 30px;

  &:hover {
    background-color: #3370E8;
  }

  > div {
    background: $prosper-icon;
    background-size: 40px;
    background-position: 40px center;

    > p:first-of-type {
      font-size: 24px;
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 0.07em;
      margin: 0 0 0 20px;
    }

    > p:last-of-type {
      font-size: 17px;
      color: #ffffff;
      font-family: 'Zilla Slab';
      font-style: italic;
      font-weight: 400;
      margin: 0 0 0 16px;
    }

  }

}
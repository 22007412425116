.container {
  width: 190px;
  height: 60px;
  flex: 0 0 190px;
  border: 1px solid #d4f8ef;
  box-shadow: 0 3px 6px rgba(41, 181, 115, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 47px 1fr;
}

.left, .right {
  display: inline-block;
}

.left {
  position: relative;
  height: 100%;
  border-radius: 4px 0 0 4px;
  background-color: #FCA976;
  vertical-align: top;
}

.right {
  padding: 5px 15px;
}

.errorIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  position: relative;
  color: #ffffff;
}

.errorMessage {
  font-size: .875rem;
  line-height: 1rem;
  margin: 0;
}
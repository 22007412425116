.container {
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  color: #1F2E4D;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.separator {
  border-top: 1px solid #d7d7d7;
  width: 100%;
  margin-bottom: 10px;
}

.flexContainer, .summaryTotalContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.summary {
  height: 100%;
  width: calc(40% - 30px);
  align-self: flex-start;
  margin-top: 1.3rem;
}

.summaryHeader {
  font-family: 'Zilla Slab';
  font-size: 20px;
  letter-spacing: 0;
  color: #656565;
  font-weight: 600;
  margin: 0;
  margin-top: 0;
}

.summaryDescription {
  font-family: 'Zilla Slab';
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  color:#656565;
  margin-bottom: 0;
}

.summaryPlanCostHeader {
  font-size: 16px;
  margin: 0 10px 0 0;
  color: #707070;
}

.summaryPlanCost {
  font-size: 24px;
  margin: 0;
  text-align: right;
  font-weight: 500;
  color: #707070;
}

.summaryMo {
  font-size:14px;
  margin: 0;
  font-weight: 600;
}

.summaryPlanEmployees {
  font-size: 16px;
  letter-spacing: 0;
  color: #406FCC;
  font-weight: 500;
  margin: 0 0 10px 0;
}

.summaryEstimateTotal {
  font-weight: 600;
  font-size: 14px;
  margin:0
}

.costArea {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: space-between;
}

.summaryTotalCost {
  color: #29B573;
  font-size: 24px;
  margin: 0;
  text-align: right;
  font-weight: 700;
}

.summaryTotalCost span {
  font-weight: 700;
}

.estCostHeader {
  color: #406FCC;
  font-size: 30px;
  font-weight: 700;
  margin: 15px 0 0;
}

.nextButton {
  height: 40px;
  width: 100%;
  background-color: #48a3db;
  color: #ffffff;
  font-size: 14px;
}

.nextButton:hover {
  font-size: 14.5px;
}
@import 'Components/global.scss';

.mainContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: $proxima;
    font-weight: 600;
    margin: 1.5rem 0;
    text-align: left !important;
  }
}

.headerContainer {
  width: 100%
}

.titleContainer {
  height: 125px;
  background-color: #16346F;
}

.titleContainer > h1 {
  margin-top: 0;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-align: center;
  line-height: 125px;
}

.cartContainer {
  background-color: #3564B9;
  padding: 50px 0;
}

.cartContainer > div {
  width: 860px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.orderedList {
  list-style: none;
  counter-reset: item;
  margin-right: 30px;
  flex: 0 0 300px;
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.enrollInfo {
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 10px;
  counter-increment: item;
  padding-left: 1em;
  text-indent: -1.75em;
}

.enrollInfo::before {
  content: counter(item);
  color: white;
  font-size: 36px;
  font-family: 'Zilla Slab';
  font-weight: 700;
  text-align: right;
  display: inline-block;
  width: 10px;
  margin-right: .7em;
}

.plansContainer {
  margin: 0 auto;
  max-width: $container-max-width;
  min-width: $container-min-width;
  padding: $container-padding-horizontal;
}

.flexContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  justify-items: space-between;
  align-items: flex-start;
}

.coverageContributionContainer {
  border-radius: 3px;
  background-color: #f3f3f3;
  padding: 8px;
  margin-top: 25px;
  width: 100%;
}

.contInputTitle {
  color: #959595;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}

.contInputMessage {
  color: #959595;
  text-align: center;
  margin-top: 0;
  font-size: 18px;
  font-weight: 400;
}

.contributionInput {
  border: none;
  height: 99%;
  outline: none;
  font-size: 22px;
  color: gray;
  width: 148px;
  text-align: center;

}

.contributionInputContainer {
  width: 228px;
  height: 58px;
  border-radius: 7px;
  border: 1px solid #d3d3d3;
  background-color: #fcfcfc;
  margin: 30px auto 0 auto;
  vertical-align: top;
}

.contributionTypeSelect {
  width: 50%;
  display: inline-block;
  color: white !important;

}

.contributionTypeSelect > * {
  background-color: #ffa209 !important;
  color: white !important;
}

.contributionTypeSelect > * > * > * {
  color: white !important;
  font-size: 21px !important;
  margin-left: -4px !important;
}

.contributionInstructions {
  text-align: center;
  color: #959595;
  font-size: 16px;
  font-weight: 400;
}

.ancillaryTitle {
  font-size: 18px;
  color: #959595;
  font-weight: 400;
}

.selectionReview {
  flex-grow: 1;
  width: 100%;
}

.planContainer {
  justify-content: space-between;
  flex-wrap: nowrap;
}

.backToSelect {
  flex-grow: 1;
  text-align: center;
  background-color: #F3F3F3;
  width: 100%;
  height: 94px;
  padding: 20px;
  margin-top: 24px;
  cursor: pointer;
}

.addPlanPlus {
  color: #959595;
  background-color: none;
}

.circlePlus{
  border:2px solid #959595;
  width:18px;
  height:18px;
  border-radius:100%;
  position:relative;
  margin:4px;
  display:inline-block;
  vertical-align:middle;
}

.circlePlus:before,
.circlePlus:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #959595;
}

.circlePlus:before{
  width: 2px;
  margin: 2px auto;
}

.circlePlus:after{
  margin: auto 2px;
  height: 2px;
}

.planCount {
  margin: 0 0;
}

.planPremiumAmt {
  text-align: left;
  font-size: 46px;
  font-weight: 600;
}

.startButton {
  background-color: #E92569;
  border-radius: 7px;
  color: white;
  font-size: 15px;
  margin-top: 32px;
  width: 232px;
  height: 53px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.36);
  border: none;
  cursor: pointer;
}

.startButton:disabled {
  opacity: 0.5;
}

.backToPlans {
  position: relative;
  text-decoration: underline;
  cursor: pointer;
}

.backToPlans {
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.numberOfPlans {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 17px;
  font-weight: 600;
}

.contributionArea {
  float: right;
}

.monthlyPremBreakdownLabel {
  color: #2C2C2C;
  font-size: 14px;
  margin: 5px 0 10px;
}

.monthlyPremBreakdownLabel > span {
  color: #17346F;
  font-weight: 600;
}

.bar {
  transition: height 500ms;
  margin-bottom: 2px;
  position: relative;
}

.contributionBar {
  display: inline-block;
  width: 25%;
  padding: 0 1px;
  vertical-align: bottom;
}

.labelContainer {
  display: flex;
  height: 100%;
  align-items: center;
  color: #16346f;
  font-size: .875rem;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.label {
  margin: auto;
  text-align: center;
}

.premiumContainer > span {
  display: block;
  color: #48a3db;
}


.premium {
  font-size: 30px;
}

@import 'Components/global.scss';

.ask {
  position: relative;
}

.ask h2, .ask p {
  display: inline-block;
}

.ask h2 {
  margin-right: 5px;
}

.ask input[type="text"].candor {
  width: 100%;
  margin-bottom: 15px;
}

.ask input[type="text"].candor.half {
  width: calc(50% - 7.5px);
  margin-right: 15px;
}

.ask input[type="text"].candor.no-right-margin {
  margin-right: 0;
}

.body {
  background-color: hsla(210, 16%, 95%, 1);
  border-radius: 7px;
  box-shadow: none;
  border: none;
  font-family: inherit;
  font-size: 15px;
  color: hsla(0, 0%, 50%, 1);
  padding: 17px 23px;
  width: 100%;
  height: 385px;
  resize: none;
}

.subject {
  background-color: hsla(210, 16%, 95%, 1);
  border-radius: 7px;
  box-shadow: none;
  border: none;
  font-family: inherit;
  font-size: 15px;
  color: hsla(0, 0%, 50%, 1);
  padding: 17px 23px;
  width: 100%;
  margin-bottom: 20px;
}

.sendButton {
  position: absolute;
  border-radius: 3px;
  width: 133px;
  height: 31px;
  right: 30px;
  bottom: 40px;
  background-color: $blue;
  color: white;
  border: none;
  outline: none;
}

.sendButton:disabled {
  opacity: 0.5;
}

.displayConf {
  text-align: center;
  margin-top: 10px;
  visibility: visible;
}

.hideConf {
  text-align: center;
  margin-top: 10px;
  visibility: hidden;
}

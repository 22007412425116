@import 'Components/global.scss';

.static {
  background-color: white;
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;
}

.mjm .header {
  height: 5rem;
}

.candor .header {
  height: 3.875rem;
}

.header {
  display: flex;
  align-items: center;
  padding: 0 $container-padding-horizontal;
  margin: 0 auto;
  justify-content: space-between;
  min-width: $container-min-width;
  max-width: $container-max-width;

  a.logos {
    display: flex;
    align-items: center;
    height: 100%;
    padding: .75rem 0;

    .logo {
      height: 100%;
      width: auto;

      &:not(last-child) {
        margin-right: 1rem;
      }
    }
  }
}

.banner {
  width: 100%;
  padding-top: 2px;
  height: 28px;
  font-size: 0.7em;
  display: flex;
  align-items: center;
  background-color: rgb(40, 67, 109);
  color: white;

  span {
    max-width: $container-max-width;
    min-width: $container-min-width;
    flex: 1;
    margin: 0 auto;
    padding: 0 $container-padding-horizontal;
  }

}

.help {
  text-align: center;
  color: #1F2E4D;

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  p {
    font-size: 0.8rem;
    margin: 0;
    color: #A7A7A7;
  }

  .bigger {
    font-size: 1.25em;
    font-weight: 700;
    color: $blue;
    margin: 0.1em 0;
  }

  .smaller {
    font-size: 0.7em;
  }
}

button.question {
  background-color: $mint;
  color: $secondary-color;
  width: 180px;
  height: 45px;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  transition: box-shadow 0.3s;

  &:active {
    background-color: darken($mint, 10%);
  }

  &:hover {
    box-shadow: 0px 6px 6px -3px rgba(0,0,0,0.25);
  }
}

.ieBanner {
  width: 100%;
  background-color: #FDB494;
  padding: 10px;
  color: #3C3C3C;
  text-align: center;
  font-weight: 600;
  font-size: .7em;

  a {
    margin: 0;
    padding: 0 !important;
    cursor: pointer;

    &:link,
    &:visited {
      padding: 10px;
      color: #3C3C3C;
      font-weight: 700;
    }

  }

}
@import './Plan.module.scss';

.pbcontainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 0 0 25%;
  margin-right: $box-margin * 2;

  > div {
    flex: 1 1 125px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-of-type {
      margin-bottom: $box-margin;
    }
  }

}

.sectionTitle {
  font-size: .875rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .05rem;
}

.erCost {
  color: $green;
  font-size: 2.75rem;
  line-height: 2rem;
  margin-top: .5rem;
  font-weight: 600;

  span:first-of-type{
    display: inline-block;
    vertical-align: top;
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  span:last-of-type{
    font-size: 1.5rem;
  }
}

.perEmployee {
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08rem;
}

.premiums {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: .25rem;

  > div {
    width: 50%;
    text-align: center;

    // Tier
    span {
      font-size: .75rem;
      font-weight: 600;
      letter-spacing: .02rem;
    }

    div {
      font-size: 1rem;
      color: $green;
      font-weight: 600;
    }

  }

}
@import 'Components/global.scss';

$switch-height: 2.25rem;
$switch-width: 6.75rem;

.switch {
  width:  $switch-width;
  height: $switch-height;
  border-radius: 4px;
  overflow: hidden;

  button {
    display: inline-block;
    height: 100%;
    line-height: $switch-height;
    width: 50%;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    &:first-of-type {
      border-right: 1px solid $dodo;
    }

  }

  .selected {
    background-color: $periwinkle;
    color: white;

  }

  .unselected {
    background-color: $gray;
    color: $placeholder-gray;
  }

}
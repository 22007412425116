@import 'Components/global.scss';

.plansContainer {
  width: 100%;
  max-width: $container-max-width;
  min-width: $container-min-width;
  padding: 0 $container-padding-horizontal;
  margin: 2rem auto 0;
}

.disclaimer {
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}
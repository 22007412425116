.container {
  height: 100%;
  padding: .75rem 0;
  max-width: fit-content;
  white-space: nowrap;
}

.container > * {
  display: inline-block;
  vertical-align: middle;
}

.container > div {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.userProfileImg {
  border-radius: 50%;
  margin-right: 0.6rem;
}

.userName {
  white-space: nowrap;
  display: block;
  font-size: .875rem;
  font-weight: 600;
  line-height: .875rem;
  margin-bottom: .25rem;
}

.profileMenuItem {
  cursor: pointer;
  text-decoration: underline;
  color: rgba(149, 149, 149, 1);
  font-size: .75rem;
  line-height: .75rem;

}

.container a {
  text-decoration: none;
  color: inherit;
}

.container a:hover {
  text-decoration: underline;
}

.contributionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.amount {
  height: 55px;
  border-radius: 8px;
  background-color: #f4f4f4;
  flex: 0 1 30%;
  margin-right: 15px;
  min-width: 0;
  color: #1F2E4D;
}

input[type=number].amount::-webkit-inner-spin-button,
input[type=number].amount::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  min-width: 0;
}

.amount::placeholder {
  color: #959595;
}

input.amount {
  font-size: 34px;
  font-weight: 500;
  padding: 8px;
  text-align: right;
  border: none;
  -moz-appearance:textfield; /* Firefox */
  font-family: inherit;
}

.contType {
  height: 55px;
  border-radius: 5px;
  flex: 0 1 70%;
}

.contType > div > div:first-of-type > div > div {
  color: #1F2E4D;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.contType > div > div:nth-of-type(2) > div > div {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  text-transform: uppercase;
}

.contributionButton {
  display: inline-block;
  vertical-align: top;
  height: 55px;
  width: 90px;
  padding-left: 18px;
  padding-right: 40px;

  -webkit-appearance: none;
  -moz-appearance: none;

  border: none;
  border-radius: 8px;

  color: #1F2E4D;
  font-size: 34px;
  font-weight: 600;
  text-align: right;

  /* Select drop down arrow */
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5.48 3.38'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%231f2e4d;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EDown carrot%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath id='Path_1345' data-name='Path 1345' class='cls-1' d='M.64,0l2.1,2.09L4.84,0l.64.64L2.74,3.38,0,.64Z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
  background-size: 12px;
  background-color: #F3F4F5;
  background-position: calc(100% - 8px) center;
}

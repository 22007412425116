@import 'Components/global.scss';

.container {
  width: 100%;

  > p:first-child {
    font-size: 1rem;
    color: $secondary-color;
  }

  .filterItemContainer {
    border-top: 2px solid $dodo;
    padding: 15px 0;

  }

}
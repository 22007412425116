@import 'Components/global.scss';

$box-margin: .625rem;
$corner-radius: .25rem;

.container {
  width: 100%;
  overflow: hidden;
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &, > * {
    transition: all 200ms ease-out;
  }

  &.selected {
    width: calc(100% + 1.25rem);

    .planBodycontainer {
      width: calc(100% - 1.25rem) !important;
    }

  }

}

.planBodycontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: $gray;
  padding-left: 1.25rem;
  padding-bottom: $box-margin;
  border-bottom-left-radius: $corner-radius;
  border-bottom-right-radius: $corner-radius;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
}

.section {
  margin: $box-margin $box-margin 0 0;
}

.box {
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid #EBEBEB;
  padding: 15px;
  overflow: hidden;
  margin-right: $box-margin;;
  margin-bottom: $box-margin;
}

.label {
  text-align: center;
  font-size: .875rem;
  font-weight: 600;
}

.placeholderContainer {
  border-top-left-radius: $corner-radius;
  border-bottom-left-radius: $corner-radius;
  border-bottom-right-radius: $corner-radius;
  background-color: $gray;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  margin-bottom: 1.25rem;

  //Loader
  div > div {
    background-color: $dodo;
  }

}
.contributionGraph {
  /* margin-right: 100px; */
}

.eeBarLabel {
  position: absolute;
  right: -110px;
  top: -20px;
  color: #48a3db;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.06px;

}

.erBarLabel {
  position: absolute;
  right: -108px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.06px;
}

@import 'Components/global.scss';

.container {
  flex: 0 0 172px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
}

.unselectedBG _{
  background: #F8F8F8;
}

.selectedBG {
  background: linear-gradient(to right, #E4E4E4, #F4F4F4);
}

/* The checkLabel */
.checkLabel {
  display: block;
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 80px;
  font-size: 16px;
  margin-left: 20px;
  padding-left: 35px;
  text-transform: uppercase;
}

.assLabel {
  width: fit-content;
  text-transform: none;
}

.assLabel > .checkmark {
  background-color: #E8E8E8;
  border: none;

}

/* Hide the browser's default checkbox */
.checkLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $disabled-gray;
}

/* On mouse-over, add a grey background color */
.checkLabel:hover input ~ .blueCheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkLabel input:checked ~ .blueCheckmark {
  background-color: $blue;
  border: 0px;
}

.checkLabel:hover input ~ .peachCheckmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkLabel input:checked ~ .peachCheckmark {
  background-color: #E09E74;
  border: 0px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "√";
  font-family: Verdana, sans-serif;
  font-size: 22px;
  color: white;
  position: absolute;
  display: none;
  left: 3px;
  top: -3px;
}

.peachCheckmark {
  z-index: 2;
}

.peachCheckmark:after {
  color: $disabled-gray;
}

/* Show the checkmark when checked */
.checkLabel input:checked ~ .checkmark:after {
  display: block;
}

/* Terms and Conditions */

.termsContainer {
  align-self: center;
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.termsLabel {
  font-size: 18px;
  text-transform: none;
  width: fit-content;
  padding-left: 25px;
  color: #AFAFAF;
  margin: 0;
}

.termsLabel > .checkmark {
  height: 16px;
  width: 16px;
  border: 1px solid #D5D5D5 !important;
  top: 2px;
}

.termsLabel > .checkmark:after {
  font-size: 19px;
  color: #D5D5D5;
  left: -1px;
  top: -6px;
}

.termsLabel input:checked ~ .checkmark {
  background-color: $blue;
}

.termsLabel ~ button {
  font-size: 18px;
  color: #AFAFAF;
  font-family: proxima-nova, sans-serif;
  padding: 3px;
  text-decoration: underline;
}
@import 'Components/global.scss';

.container {
  height: 90px;
  width: 100vw;
  position: fixed;
  left: 0;
  background-color: #ECFAF6;
  bottom: 0;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $container-max-width;
    min-width: $container-min-width;
    margin: 0 auto;
    padding: 0 120px;
    width: 100%;
    height: 100%;

    .planContainer {
      overflow: hidden;
      width: 100%;
      height: 65px;
      text-align: end;
      white-space : nowrap;
      margin: 0 10px;
    }

    > button {
      float: right;
      height: 53px;
      margin-bottom: 5px;
      background-color: $green;
      color: white;
      font-size: .875rem;
      border-radius: 4px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      align-items: top;
      flex: 0 0 110px;
    }

  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #ECFAF6, $alpha: .7);
    z-index: 4;
    padding-top: 10px;
  }

}
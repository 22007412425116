@import './Plan.module.scss';

.fundingTypeContainer {
  height: 1.5rem;
  text-transform: uppercase;
  font-size: .625rem;
  font-weight: 700;
  letter-spacing: .09rem;
  width: calc(100% - 200px);
  border-top-left-radius: $corner-radius;
  border-top-right-radius: $corner-radius;
  line-height: 1.6rem; // Greater than the height to visually center
  padding: 0 1.25rem;
  color: $navy-blue;
}

.planTitleContainer {
  width: 100%;
  height: 3.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $gray;
  padding-right: 1.25rem;
  min-width: 0;
  border-top-left-radius: $corner-radius;
  position: relative;
  overflow: hidden;

  &:first-child {
    border-top-right-radius: $corner-radius;
  }

  img{
    margin-top: .25rem; // To Visually center
    &:first-child { margin-left: 1.25rem; }
  }

}

.title {
  flex: 1;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 1.5rem;
  min-width: 0;
  margin-top: .25rem; // To Visually center
  display: flex;
  flex-direction: column;
  justify-content: center;

  &, & > div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .prosper {
    font-size: .75rem;
    color: $light-periwinkle;
  }

}

.remove{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $secondary-color;
  opacity: .4;
}

// View SBC/Details Button
.viewDetails {
  height: 1.875rem;
  width: 7.5rem;
  background-color: $mint;
  border-radius: $corner-radius;
  font-size: .75rem;
  color: $secondary-color;
  font-weight: 500;
  border: 1px solid darken($color: $mint, $amount: 5);
  box-shadow: 0px 2px 6px rgba($color: $secondary-color, $alpha: .1);
  transition: all 200ms ease-out;
  margin-top: .3125rem; // To Visually center

  &:hover {
    transform: scale(1.04);
  }

  &:active {
    transform: scale(.97);
    box-shadow: 0px 1px 3px rgba($color: $secondary-color, $alpha: .05);
  }
}

.whatAreThose {
  border-bottom: solid 1px $navy-blue;
  margin-left: 1.25rem;
  font-family: $zilla;
  font-weight: 400;
  font-style: italic;
  color: $navy-blue
}

.explanationContainer {
  h1, h2 {
    color: $navy-blue
  }

  h2 {
    font-size: 1rem;
    font-style: italic;
    font-family: $zilla;
    font-weight: 600;
  }

  p, ul { color: $secondary-color }

  ul {
    padding-left: 1.75rem;
  }
}
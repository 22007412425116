@import 'Components/global.scss';

$h1-font-weight: 600;
$h1-font-size: 1.75rem;
$h1-line-height: unset;

.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  h1 {
    font-weight: $h1-font-weight;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
    color: $secondary-color;
    margin: 0;
  }

}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(112, 112, 112, .4);
  z-index: 4;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.closeInner {
  background: $close-icon-blue;
  background-size: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 50;
  transition: transform 200ms ease-in-out;
  overflow: hidden;
  outline: none;
  border: 0;

  &:hover {
    transform: scale(1.1, 1.1);
  }

  &:active {
    transform: scale(0.9, 0.9);
  }
}

.closeOuter {
  background: $close-icon-blue;
  background-position: center;
  background-size: 30px;
  background-color: $white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: absolute;
  right: -30px;
  top: -30px;
  z-index: 50;
  transition: transform 200ms ease-in-out;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);

  &:hover {
    transform: scale(1.05, 1.05);
  }

  &:active {
    transform: scale(0.9, 0.9);
  }

}


@import 'Components/global.scss';

.mainContainer {
  width: 800px;
  margin: 0 auto;
}

.form {

  margin-top: 3rem;

  .questionsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 1.5rem;
  }

  input[type=submit] {
    margin-top: 2rem;
  }

  span {
    display: block;
    padding-bottom: .5rem;
  }

}

.radio {
  position: relative;

  label {
    display: inline-block;
    padding: .75rem .5rem .75rem 2rem;
    cursor: pointer;
  }

  input[type=radio] {
    appearance: none;
    background: none;
    opacity: .00001;
    z-index: 2;
  }

  input:checked ~ label:after {
    border-color: white;
    border-width: 4px;
    box-shadow: inset 0 0 0 5px $blue;
  }

  input:checked ~ label:before {
    border-color: transparent;
    box-shadow: 0 0 0 2px $blue;
}

  label:after, label:before {
    border: 1px solid;
    height: 1.125em;
    left: .125em;
    position: absolute;
    top: .825em;
    width: 1.125em;
    border-radius: 100%;
    content: " ";
    box-sizing: border-box;
    transition: border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  }
}

.submitArea {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@import 'Components/global.scss';
@import 'Components/Rudimentary/CandorSwitch.module.scss';

.mainContainer {
  margin: 0 auto;
  text-align: center;

  h1 {
    margin-top: 10px;
  }

}

.header {
  width: 800px;
  margin: 0 auto;
}

.form {
  width: 75%;
  margin: 0 auto;
  column-gap: 20px;
  row-gap: 25px;
  margin-top: 50px;
  text-align: left;

  .switch {
    position: absolute;
    left: 0;
    top: 0;
  }

  label {
    display: block;
    min-height: $switch-height;
    position: relative;
    padding-left: $switch-width + 1rem;
    margin: 1.5rem 0;
    color: #656565;
    font-size: 1rem;
    font-weight: 500;
    color: $primary-color;
    line-height: 1.1rem;
  }

  input[type=submit] {
    display: block;
    margin: 50px auto 0;
    width: 200px;
    height: 53px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

    background: $blue;
    color: white;
    text-transform: unset;
    font-weight: 600;
    font-size: .875rem;
    border-radius: 4px;
    letter-spacing: .03rem;
    cursor: pointer;
  }

  p {
    margin: 0 0 .5rem;
  }

}

@import 'Components/global.scss';

.container {
  display: grid;
  grid-template-columns: 5fr 4fr;
  gap: 10px;
  width: 950px;
  margin: 45px auto 30px;
  z-index: 5;
  padding: 90px 0 60px;

  .cartHeader {

    position: relative;

    &:before {
      content: '';
      width: 5px;
      height: 80%;
      position: absolute;
      background-color: $mint;
      top: -15px;
      left: -30px;
    }

    h1, h2 {
      text-align: left;
      color: $navy-blue;

      i {
        font-family: inherit;
        font-weight: 600;
      }
    }

    button {
      display: block;
      font-size: 1rem;
      width: 60%;
      margin: 14px 0 0;
      color: #ffffff;
      height: 53px;
      border-radius: 4px;
      background-color: $blue;
      box-shadow: 0 2px 9px rgba(0, 0, 0, 0.16);
    }
  }

  .cartContainer {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    color: $navy-blue;

    hr {
      border: 1px solid $blue;
      margin: 15px 0;
    }

    .memberCount {
      font-size: 16px;
      width: 100%;
      margin: 10px 0;
      text-align: right;
    }

    > div > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin: 3px 0;

      &.total {
        align-items: flex-end;
      }

      p {
        margin: 0;
      }

      .planLabel {
        font-size: 1.1rem;
      }

      .figure {
        font-size: 1.5rem;
        font-weight: 600;

        span {
          font-weight: 300;
        }

      }

    }

  }

}


@import 'Components/global.scss';

$census-upload-border-radius: .75rem;

.censusUploadForm {
  position: relative;
  height: 6.25rem;
  margin-top: 1rem;
  background-color: $mint;
  border-radius: $census-upload-border-radius;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  fieldset {
    display: contents;
  }

  p {
    font-size: 1.5rem;
    color: $secondary-color;
    margin-left: 2rem;
    margin-right: 2.9rem;
    flex: 3.2;
  }

  a {
    color: $secondary-color;
    font-weight: 600;
  }

  label {
    cursor: pointer;
    background-color: #f8f8f8;
    border-radius: $census-upload-border-radius;
    text-align: center;
    padding: 1.1rem .25rem 0;
    flex: 2;
    height: 100%;
    // Dashed border since CSS doesn't allow any control over the dashedness of the border
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2329B573FF' stroke-width='4' stroke-dasharray='2%2c 7' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

    span {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
      color: $secondary-color;
      //Custom Border https://css-tricks.com/styling-underlines-web/
      box-shadow:
        inset 0 -0.25rem #f8f8f8,
        inset 0 -0.1rem $mint;
      display: inline;
      box-shadow:
      inset 0 -0.25rem #f8f8f8,
      inset 0 -0.45rem $mint;
      padding: .3rem 0;
    }

  }

  //To show the custom text of the label
  input[type=file] {
    visibility: hidden;
    width: .01rem;
    height: .01rem;
  }

}

.loaderContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: $mint, $alpha: .8);
  border-radius: $census-upload-border-radius;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

}
@import 'Components/global.scss';

.requestReviewContainer {
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: $navy-blue;
  padding: 60px 0 80px;
  overflow: visible;

  .oval {
    position: absolute;
    width: 100%;
    height: 30px;
    top: -15px;
    border-radius: 50%;
    background-color: inherit;
  }

  div:not(.oval) {
    position: relative;
    width: 330px;
    margin: 0 auto;
    z-index: 1;

    &:after {
      content: '?';
      font-family: $zilla;
      font-weight: 300;
      font-size: 18.5rem;
      position: absolute;
      top: 40%;
      left: -30px;
      transform: translate(-50%, -50%);
      color: #204589;
      z-index: -1;
      -ms-user-select: none;
      user-select: none;
    }

    p {
      font-family: $zilla;
      font-size: 1.25rem;
      width: 100%;
      font-style: italic;
      color: white;
      margin: 0 auto;
      z-index: 4;
    }

    button {
      display: block;
      font-size: 1rem;
      width: 190px;
      margin: 40px auto 0;
      color: #ffffff;
      height: 53px;
      border-radius: 4px;
      background-color: $periwinkle;
      box-shadow: 0 2px 9px rgba(0, 0, 0, 0.16);
      z-index: 2;
    }
  }

}
@import 'Components/global.scss';

$buttonWidth: 200px;
$buttonHeight: 53px;
$savings-calc-radius: 100px;

.header {
  width: 100%;
  padding: $shop-top-spacing - 1.25rem;
  background-color: $periwinkle;
  overflow: auto;

  > div {
    max-width: $container-max-width;
    min-width: $container-min-width;
    padding: 0 $container-padding-horizontal;
    margin: 0 auto;
    display: grid;
    grid-template-columns: $buttonWidth 1fr $buttonWidth;
    justify-items: center;
    align-items: center;

    > button {
      width: $buttonWidth;
      height: $buttonHeight;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      font-size: .875rem;
      border-radius: 4px;

      &:disabled {
        opacity: .5;
      }

      &.nextButton {
        background-color: $blue;
        color: white;
      }

      &.waiveButton {
        // Not really disabled, but just the color in the design
        background-color: $disabled-gray;
        color: secondary-gray;
      }
    }
  }

  h1 {
    font-size: $shop-h1-font-size;
    font-weight: $shop-h1-font-weight;
    color: white;
    text-align: center;
    margin: 0;
    line-height: $buttonHeight;
  }

}

.savingsCalculator {
  float: left;
  box-sizing: border-box;
  width: $savings-calc-radius;
  height: $savings-calc-radius;
  border-radius: 50%;
  border: 4px solid #D4F8EF;
  background: linear-gradient(#29b5aa 0%, #29b473 100%);
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-family: $barlow;
    font-size: .8rem;
    color: inherit;
    text-align: center;
    margin: 0 0 7px 0;
    font-weight: 600;
    text-transform: uppercase;
  }

  button {
    font-family: $zilla;
    font-style: italic;
    font-size: .8rem;
    background-color: transparent;
    text-transform: lowercase;
    color: inherit;
    cursor: pointer;
    margin: 0;
    border-bottom: 1px solid $white;
  }

}
@import 'Components/global.scss';

.container {

  background-color: white;
  border-radius: .25rem;
  padding: 1.25rem;
  display: grid;
  grid-template-columns: 10rem 18.75rem;
  gap: 2.5rem;

}

.summary {
  h2 {
    font-family: $zilla;
    color: $secondary-color;
    font-size: 1.375rem;
    margin-top: 0;
  }

  em {
    font-family: $zilla;
    font-size: 1.125rem;
  }
}

.costs {

  color: $secondary-color;

  h1 {
    text-align: left;
    margin-bottom: 1rem;
  }

  h3 {
    margin: 0;
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
  }

  hr {
    margin: 1rem 0;
  }

  .total { color: $green }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &:nth-of-type(2) {
      margin-top: .5rem
    }

    span {
      font-size: 1.5rem;
    }

    &.total span {
      font-weight: 600;
    }

  }

  button {
    margin-top: 1rem;
    width: 100%;
    height: 40px;
    background: $blue;
    box-shadow: 0px 0px 6px #00000040;
    border-radius: .25rem;
    color: white;
    font-size: .875rem;
  }

}
.container h4 {
    width: 80px;
    flex-grow: 0;
    margin: 0;
    margin-top: 22px;
}

img {
    margin-top: 0px;
}

.container {
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
}

.container > div {
    flex-grow: 1;
    margin: 0 20px;
}

.bar {
    height: 4px;
    width: 100%;
    margin: 0;
    border-radius: 2px;
    background: rgb(23,52,111);
}


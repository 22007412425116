.removePlansContainer {
  margin: 30px;
}

.removePlansContainer h2 {
  color: #656565;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}

.removePlansContainer h2 > span {
  color: #F4A07A;
}

.planToRemove {
  padding: 10px 40px;
}

.planToRemove > img {
  display: block;
  margin: 0 auto;
  height: 45px;
}

.carrierName {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 2px 0;
  text-align: center;
}

.planName {
  font-size: 14px;
  color: #414141;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
}

img + .planName {
  margin-top: 1rem;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  margin: 0 auto 20px;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  width: fit-content;
}

.buttonContainer > button {
  height: 60px;
  width: 110px;
  font-size: 16px;
  color: #707070;
}

.buttonContainer span {
  font-size: 12px;
}

.active {
  background-color: #3564B9;
  color: #ffffff !important;
}

.removingAllActive {
  background-color: #FCA976;
  color: #ffffff !important;
}

.applicationButton {
  display: block;
  margin: 0 auto;
  font-size: 14px !important;
  color: #ffffff;
  width: 200px;
  height: 53px;
  border-radius: 4px !important;
  background-color: #43A3DA;
}

.plansButton {
  display: block;
  margin: 0 auto;
  font-size: 14px !important;
  color: #ffffff;
  height: 53px;
  width: 200px;
  border-radius: 4px !important;
  background-color: #29B573;
}
.flexContainer {
    margin: 30px 0;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

.option {
    margin: 0 10px;
}

.option > button {
    width: 130px;
    height: 60px;
    background-color: #3564b9;
    border-radius: 4px;
    border-width: 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.37);
    cursor: pointer;
    user-select: none;
    margin: 0;
    padding: 0;
}

.option > button:hover {
    opacity: .75;
}

button > img {
    height: 60px;
    width: auto;
}

.option > div {
    margin-top: 15px;
}
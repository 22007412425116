@import 'Components/global.scss';

.tabs {
  width: 100%;
  height: 55px;
  background-image: linear-gradient(180deg, #e4e4e4 0%, #f8f8f8 100%);
}

.tabContainer {
  align-items: center;
  height: 100%;
  display: flex;
  margin: 0 auto;
  max-width: $container-max-width;
  min-width: $container-min-width;
  padding: 0 $container-padding-horizontal;
}

.tab {
  &:not(:last-child) {
    margin-right: 64px;
  }

  a {
    height: 30px;
    color: $secondary-color;
    font-size: 16px;
    font-weight: $header-weight;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-underline-position: under;
    text-decoration: none;
    cursor: pointer;
  }
}

.active {
  text-decoration: underline;
  text-decoration-color: black;
}

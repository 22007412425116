@import 'Components/global.scss';


.closeButton {
  background-color: $blue;
  box-shadow: 0 0 3px white;
}

.closeButton > i {
  color: white;
}

.title {
  color: $periwinkle !important;
  font-size: $shop-h1-font-size !important;
  font-weight: $shop-h1-font-weight !important;
  line-height: 36px;
  text-align: center;
}

.desc {
  color: $primary-color;
  font-size: 24px;
  font-family: $zilla;
  text-align: center;
  margin-top: 0;
  text-align: center;
}

a.desc {
  color: white;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  width: 451px;
}

.input {
  height: 61px;
  width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: white;
  opacity: 0.8;
  color: $blue;
  font-size: 1rem;
  font-weight: 400;
  outline: none;
  padding: 12px;
  border: none;
  margin-bottom: 10px;
}

.input:first-of-type {
  margin-top: 10px;
}

.link {
  display: block;
  width: 100%;
  text-align: left;
  margin-left: 7px;
  background: none;
  outline: none;
  border: none;
  margin-top: 10px;
  color: $blue;
  font-size: .9rem;
  font-weight: 400;
  text-decoration: underline;
}

.nextButton {
  margin-top: 34px;
  border-radius: 8px;
  width: 227px;
  height: 62px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: $blue;
  color: white;
}

.center {
  text-align: center;
}

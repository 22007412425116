@import 'Components/global.scss';

.modalTitle {
  color: $secondary-color;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  margin: 0 0 20px;
  text-transform: uppercase;
}

.addContactForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  input {
    background-color: $white;
  }

   /*
      Separate class because adding !important to
      input's backgroundColor introduces an artifact
      in the county picker
  */
  .whiteBackground {
    background-color: $white !important;
  }

  input[type=submit] {
    width: 160px;
    align-self: flex-end;
  }

}

.addContactForm > div {
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
}

.addContactForm > div > div {
  margin-bottom: 10px;
}

.twoCol {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.twoCol > div {
  width: calc(50% - 5px);
}

.fileTemplate, .fileUploaded {
  color: #707070;
  font-size: 16px;
}

.fileTemplate > .link {
  font-weight: 600;
  color: #707070;
}

.fileUploaded > .link {
  font-weight: 700;
  color: #707070;
  background-color: #D4F8EF;
  padding: 3px 4px;
  letter-spacing: .6px;
  text-decoration: underline;
}

.upload {
  width: 75%;
  height: 55px;
  font-size: 16px;
  font-weight: 600;
  color: #707070;
  margin-top: 5px;
  text-transform: uppercase;
  background-image: linear-gradient(to top, #ebeaea 0%, #f8f8f8 100%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.saveContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkmark {
  width: 35px;
  height: 35px;
  background-color: #29B573;
  margin-right: 45px;
  border-radius: 4px;
}

.unsavedCheckmark {
  width: 35px;
  height: 35px;
  background-color: #E4E4E4;
  margin-right: 45px;
  border-radius: 4px;
}

.checkmark::before, .unsavedCheckmark::before {
  content: '';
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  height: 20px;
  width: 8px;
  margin-left: 11px;
  margin-top: 2px;
  border-bottom: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
}

.save:disabled {
  opacity: .5;
  cursor: not-allowed
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(169, 169, 169, 0.493);
}

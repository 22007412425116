.medicalTableBox {
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    overflow: hidden;
    color: #656565;
    margin: 0 10px 10px 0;
    text-align: center;
}

.medicalTableBox > div > div {
    height: 25%;
    padding: 15px;
    font-size: 17px;
    display: flex;
    font-weight: 500;
    flex-direction: column;
    justify-content: center;
}

.medicalTableBox > div > div:nth-child(2) {
  background-color: #E3EEFF;
}

.medicalTableBox > div > div:last-of-type {
  background-color: #F7F7F7;
}

.medicalPremiumTypeContainer {
    text-align: left
}

.medicalPremiumTypeContainer div {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 17px !important;
}

.medicalPremiumTypeContainer span:first-of-type {
  font-size: 10px;
  font-weight: 400;
  margin-top: 4px;
  text-transform: uppercase;
  letter-spacing: 0.09em;
}

.premiumTypeHeader, .premiumTotalColumn > div:first-of-type {
  justify-content: space-between !important;
}

.premiumTypeHeader > p:first-child,
.premiumTotalColumn > div:first-of-type > p:first-of-type {
  font-size: 10px !important;
  font-weight: 700;
  margin: 0;
  color: #16346F;
  text-transform: uppercase;
  letter-spacing: 0.07em;
}

/* ( FOR X PLANS ) copy */
.premiumTotalColumn > div p {
  font-size: 10px !important;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.07em;
}

.medicalTableBox > div > div:first-of-type {
  color: #29B473
}

.medicalTableBox > div > div:nth-child(2) {
    color: #17346F
}

.medicalTableBox > div > div:nth-child(3) {
    color: #656565
}

.medicalTableBox > div > div:last-child {
  color: #406FCC
}

.medicalTableBox > div:first-of-type > div:last-of-type {
  color: #406FCC;
}

.medicalPremiumBreakdownContainer > div {
    padding: 0 !important;
}

.medicalPremiumBreakdownContainer > div > div {
    color: #ffffff;
    background-color: rgba(41,180,115, .5);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.medicalPremiumBreakdownContainer span {
  font-size: 14px;
  font-weight: 400;
}

.premiumLabel > p {
  font-size: 32px !important;
  font-weight: 600 !important;
  margin: 0;
}

.premiumLabel > p > span {
  font-size: 24px;
}

.perEmployee {
  color: #29B473;
  font-size: 12px;
}

.medicalExpandContainer {
    padding: 0 !important;
    max-width: 30px;
    flex-grow: 1;
    max-height: 100%;
    transition: 0.15s;
}

.medicalExpandContainer > button {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    cursor: pointer;
    background-color: #E4E4E4;
    background-size: 6px;
    background-position: center 30px;
    flex-shrink: 2;
    flex-grow: 1;
    transition: background-color 200ms ease-in;
}

.medicalExpandContainer > button:hover {
  background-color: #D8D8D8;
}

.medicalExpandContainer > button:focus {
  outline: none;
}

.medicalExpandCollapsedCarrot {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4.905' height='7.943' viewBox='0 0 4.905 7.943'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %2316346F; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_1474' data-name='Path 1474' class='cls-1' d='M0,.933,3.032,3.971,0,7.01l.933.933L4.9,3.971.933,0Z'/%3E%3C/svg%3E%0A") no-repeat;
}

.medicalExpandShownCarrot {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4.905' height='7.943' viewBox='0 0 4.905 7.943'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %2316346F; %7D %3C/style%3E%3C/defs%3E%3Cpath id='Path_1474' data-name='Path 1474' class='cls-1' d='M0,.933,3.032,3.971,0,7.01l.933.933L4.9,3.971.933,0Z' transform='translate(4.905 7.943) rotate(180)'/%3E%3C/svg%3E%0A") no-repeat;
}

.medicalExpandContainer > button > div {
    width: 100%;
    color: #16346F;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 13px;
    letter-spacing: .05em;
    display: flex;
    align-items: center;
}

.medicalCollapsedHeader {
    font-size: 15px !important;
    font-weight: 500 !important;
    width: calc((100%-24)*.1448);
}

.medicalCollapsedHeader > span {
  font-size: 2px;
}
.container {
    margin: auto;
    width: 600px;
    text-align: center;
}

.bulleted {
    list-style: none;
    padding: 0;
}

.listItem {
    display: inline;
    margin: 5px;
    padding: 0;
}

.flexContainer {
    margin: 30px 0;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.stepButton {
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%;
    background-color: #48a3db;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.37);
}

.stepButton:disabled {
    opacity: 0.5;
    cursor: default;
}

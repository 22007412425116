@import 'Components/global.scss';

.pipelineContainer {
  display: flex;
  flex-direction: row;
}

//In-progress stages container
.pipelineContainer > div:first-of-type {
  margin-right: 16px;
  width: calc(57% - .5rem)
}

//Benefits transitions stages
.pipelineContainer > div:nth-child(2) {
  width: calc(43% - .5rem)
}

//Stages container
.pipelineContainer > div > div:first-child {
  display: flex;
}

.completion > a {
  width: 50%;
  height: 100%;
  display: inline-block;
  text-decoration: none;
}

.activeGroups, .inProgress {
  margin-top: .5rem;
  height: 27px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  font-size: .75rem;
  letter-spacing: .1rem;
}

.inProgress {
  background-color: $navy-blue;
}

.activeGroups {
  background-color: $gray;
}

.stages {
  display: flex;
  flex-direction: row;

  a:hover {

    path:first-of-type {
      opacity: 1;
      fill: #9696BC
    }

    text {
      fill: $off-white;
    }
  }

  // Create gap between completed and archived
  a:nth-last-of-type(2) {
    margin-right: 15px;
  }
}

.stages + .completion {
  width: calc((100% - 15px) / 7 * 6);

  .activeGroups {
    background-color: $mint;
  }

}

.activeLabel {
  color: $secondary-color;
}

.inProgressLabel {
  color: white;
}

.today {
  position: relative;
  top: -3px;
  color: $secondary-color;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
}

.title {
  display: inline-block;
  margin-right: 30px;
  color: $blue;
  font-size: 40px;
  font-weight: 500;
  line-height: 76px;
  margin-bottom: 0;
}

.archivedContainer {
  display: inline-block;
  margin-left: 1vw;
}
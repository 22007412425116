.container {
  display: flex;
  flex-direction: column;
  margin: 40px 70px;
  text-align: center;
  height: 500px;
}

.container > h2 {
  font-size: 30px;
  color: #3564B9;
  font-weight: 700;
  margin: 0;
}

.container > p:first-of-type {
  font-size: 19px;
  color: #3564B9;
  margin: 10px 40px 0;
}

.searchBar{
  font-size: 20px;
  color: #27436E;
  height: 60px;
  background-color: #ffffff;
  line-height: 77px;
  margin: 20px 0 0;
  border: 0px;
  text-align: left;
  cursor: pointer;
  padding: 20px;
  border-radius: 6px;
}

.searchBar::placeholder {
  color: #CBCBCB;
}

.sendButton{
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.06em;
  background-color: #3564B9;
  color: #ffffff;
  height: 70px;
  border-radius: 8px;
  width: 450px;
  transition: background-color 200ms ease-in;
}

.sendButton:hover {
  background-color: #1F4AA0;
}

.membersListContainer {
  flex-grow: 1;
  margin: 30px 0;
  position: relative;
  height: 190px;
}

.memberList {
  width: 100%;
  height: 190px;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: flex-start;
  align-content: flex-start;
  padding-bottom: 20px;
}

.greyGradient {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, rgba(248,248,248,1) 0%, rgba(248,248,248,0) 100%);
}

.memberItem {
  font-size: 16px;
  height: 65px;
  border-radius: 8px;
  padding: 0 20px;
  max-width: 100%;
  transition: color 200ms ease-in, background-color 200ms ease-in;
  margin: 0 15px 15px 0;
  text-align: left;
}

.memberItem > span {
  font-size: 13px;
}

.memberItemDeselected {
  background-color: #EBEBEB;
  color: #959595;
  transition: background-color 200ms ease-in;
}

.memberItemDeselected:hover {
  background-color: #D8D8D8;
}

.memberItemSelected {
  background-color: #48A3DB;
  color: #ffffff;
  transition: background-color 200ms ease-in;
}

.memberItemSelected:hover {
  background-color: #2593D6;
}
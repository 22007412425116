.cartContainer {
  background-color: #F4FAF8;
  position: relative;
  width: 100%;
}

.oval {
  background-color: #3564B9;
  width: calc(100%);
  height: 180px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  position: absolute;
  top: -130px;
}
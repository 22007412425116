@import './plan-subcomponents/Plan.module.scss';

.specs {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.spec {
  flex: 0 0 calc(33.33% - #{$box-margin});
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: .875rem;
  font-weight: 600;

  > div:nth-child(2) {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: $navy-blue;
    margin: .5rem 0 0;
  }

  span {
    font-size: .875rem;
    display: block;
    color: $navy-blue;
    line-height: .875rem;
    margin-top: .5rem;
  }

}

.separateSpecs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  div:first-child {
    margin-right: $box-margin * 4;
  }
}
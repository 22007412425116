.container {
  height: 100%;
  overflow-y: visible;
}

.header {
  text-align: center;
}

.text {
  text-align: center;
  font-size: 1.25rem;
}

.buttonContainer, .waivingContainer {
  text-align: center;
}

.button {
  width: 70%;
  border: none;
  outline: none;
  height: 52px;
  background-color: #F3F3F3;
  color: hsla(0, 0%, 50%, 1);
  font-size: 15px;
  box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.12);
  border-radius: 3px;
  margin-top: 29px;
}

.button::first-letter {
  text-transform: capitalize;
}

.cancel {
  box-shadow: none;
  background: none;
  height: initial;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 30px;
}

.waiveButton {
  margin: 0;
}

.reason {
  padding: 15px 30px 30px;
}


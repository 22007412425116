.container {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to top, #ebeaea 0%, #f8f8f8 100%);
  width: 345px;
  min-height: 310px;
  padding: 20px 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-bottom: 10px;
}

.header h1 {
  color: #707070;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  line-height: unset;
}

.addTodo {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  height: 36px;
  color: #707070;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.addTodo i {
  color: #29b473;
  border-radius: 50%;
  margin-left: 5px;font-size: 28px;
}

.todos {
  display: block;
  overflow-y: auto;
}

.todo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  margin-top: 5px;
}

.check {
  appearance: none;
  outline: none;
  width: 19px;
  height: 19px;
  margin-right: 7px;
  border: 1px solid #707070;
  background-color: #ffffff;
  content: none;
}

.check:checked {
  background-color: #29b473;
  border-color: #29b473;
}

.check:checked::before {
  content: '';
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 4px;
  margin-left: 5px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.text {
  box-sizing: border-box;
  background: none;
  border: none;
  border-radius: 4px;
  color: #707070;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.07px;
  line-height: 2rem;
  outline: none;
  flex: 1;
  margin-right: 10px;
  padding: 0 10px 0 8px;
}

button.text {
  text-align: left;
  text-decoration: underline;
}

.text:focus {
  background-color:#E5E5E5;
}

.todo input[type=checkbox].check:checked + .text {
  text-decoration: line-through;
}

.noTodos {
  display: block;
  align-self: center;
  margin: auto;
  padding-bottom: 56px; /* .header height */
}

.delete {
  background-color: transparent;
  margin-right: 6px;
  color: #979696;
  transition: color 200ms ease-out;
}

.delete i {
  font-size: 1rem;
}

.delete:hover {
  color: #707070;
}
@import 'Components/global.scss';

.mainContainer {
  display: grid;
  grid-template-columns: $profile-section-grid;
  justify-items: center;
  gap: 40px;
}

.todoEnrollment {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.todoEnrollment > :not(:first-child) {
  margin-top: 15px;
}

.contactDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  div[role=button] {
    text-transform: none;
    letter-spacing: .03rem;
    border-bottom: 1px solid $primary-color;
    margin-bottom: .125rem;
  }

  i[role=button] {
    margin-right: .5rem;
  }

}

.addNewContact {
  background: $blue-gradient;
  padding: .5rem 1rem;
  border-radius: .25rem;
  color: white;
}

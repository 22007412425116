@import './plan-subcomponents/Plan.module.scss';

.specs {
  flex: 1;
  padding-right: $box-margin;
  padding-bottom: $box-margin;

  table {
    width: 100%;
    height: 100%;
    font-size: .875rem;
    text-align: left;
    border: 1px solid #EBEBEB;
    padding: 0;
    color: $secondary-color;
    border-collapse: collapse;

    th { color: $navy-blue }

    th:first-of-type, td:first-of-type { border-left: none; }

    th, td {
      padding: .75rem .875rem;
      margin: 0;
      font-size: .875rem;
      border-left: 1px solid #EBEBEB;
      vertical-align: text-top;
    }

    tbody tr:nth-child(odd) {
      background-color: lighten($color: $blue, $amount: 40);
    }
  }
}
.close {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='106' height='106' viewBox='0 0 106 106'%3E%3Cdefs%3E%3Cfilter id='a' x='0' y='0' width='106' height='106' filterUnits='userSpaceOnUse'%3E%3CfeOffset input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='8' result='b'/%3E%3CfeFlood flood-color='%2397b1d9' flood-opacity='0.353'/%3E%3CfeComposite operator='in' in2='b'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='translate(-369 -554)'%3E%3Cg transform='matrix(1, 0, 0, 1, 369, 554)' filter='url(%23a)'%3E%3Ccircle cx='29' cy='29' r='29' transform='translate(24 24)' fill='%23fff'/%3E%3C/g%3E%3Cg transform='translate(1.5 -0.5)'%3E%3Cline x2='27' y2='28' transform='translate(407.5 593.5)' fill='none' stroke='%23e3eeff' stroke-width='6'/%3E%3Cline x1='27' y2='28' transform='translate(407.5 593.5)' fill='none' stroke='%23e3eeff' stroke-width='6'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
  background-size: 80px;
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0px;
  z-index: 50;
  transition: transform 200ms ease-in-out ;
}

.close:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.prosperIncluded {
  padding: 40px 75px;
  height: 100%;
  position: relative;
}

.prosperLogo {
  height: 67px;
  width: 234px;
  position: absolute;
  left: 40px;
  top: 40px;
}

.prosperBenefitsImage {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.prosperIncluded > h1{
  font-size: 36px;
  font-weight: 700;
  color: #406FCC;
  text-align: center;
}

.prosperIncluded > h2 {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  color: #707070;
  margin-top: 70px;
}

.prosperIncluded > p {
  font-family: 'Zilla Slab';
  font-size: 20px;
  color: #707070;
  margin-top: 25px;
}

.stepNav{
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.stepButton {
  height: 26px;
  width: 26px;
  border-radius: 100%;
  background-color: #ffffff;
  box-shadow: 0 2px 6px hsla(0, 0%, 0%, 0.3);
  margin-right: 30px;
  transition: transform 70ms ease-in;

}

.stepButton:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.stepButton:last-of-type {
  margin: 0;
}

.stepButtonActive {
  background-color: #3564B9;
}

.prev i, .next i {
  color: white;
  font-size: 80px;
}

.prev, .next {
  background: none;
  position: absolute;
  z-index: 50;
  transition: transform 200ms ease-in-out ;
}

.prev:hover, .next:hover {
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.prev {
  top: calc(50% - 40px);
  left: -20px;
}

.next {
  top: calc(50% - 40px);
  right: -20px;
}
